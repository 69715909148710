import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state() {
        return {
          msalConfig: {
            auth: {
              clientId: 'e4baa2ad-ca43-4183-8c22-a0cf18a6113d',
              authority: 'https://login.microsoftonline.com/d21b59ec-2c9f-4bfe-ac8d-6a7aabf87e19',
            },
            cache: {
              cacheLocation: 'localStorage',
            },
          },
          accessToken: '',
          user: {}
        };
      },
      mutations :{
        setAccessToken(state, token){
          state.accessToken = token;
        },
        setUser(state, user) {
          state.user = user
        }
      },
      actions: {
        setUser(state, user) {
          this.commit("setUser", user)
        }
      },
      getters : {
        user (state) {
          return state.user
        },
      }

})

export default store