<template>
  <v-row justify="center">
    <v-card min-width="500">
      <v-card-title>Select a file and get a link</v-card-title>

      <v-card-text>
        <v-row no-gutters justify="center" align="center">
          <v-col md="12">
            <v-file-input
              show-size
              v-model="fileinput"
              clearable="true"
              label="Choose a file"
              @change="selectFile"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="upload()" class="deep-purple white--text">Upload</v-btn>
      </v-card-actions>
      <v-card-text v-if="share_uuid">
        <v-alert dense text type="success">
          File was uploaded successfully
        </v-alert>
        <v-text-field
          messages="Copy this link to share it!"
          append-icon="mdi-content-copy"
          readonly
          @click:append="copy"
          ref="share_url"
          :value="share_url"
        >
        </v-text-field>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import FilesService from "@/services/files";

export default {
  name: "Upload",
  data() {
    return {
      selectedFile: undefined,
      progress: 0,
      message: "",
      share_uuid: "",
      fileinput: null,
    };
  },
  computed: {
    share_url() {
      return "https://share.blueberry.bg/d/" + this.share_uuid;
    },
  },
  methods: {
    copy() {
      let share_url = this.$refs.share_url.$el.querySelector("input");
      share_url.select();
      document.execCommand("copy");
    },
    selectFile(file) {
      this.progress = 0;
      this.selectedFile = file;
    },
    upload() {
      FilesService.upload(this.selectedFile)
        .then((response) => {
          this.message = response.data.message;
          console.log(response);
          this.share_uuid = response.data.uuid;
          this.fileinput = null;
          //return UploadService.getFiles();
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.selectedFile = undefined;
        });
    },
  },
};
</script>


<style scoped>
</style>