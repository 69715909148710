import http from "./http-common";

class FilesService {
  upload(file) {
    let formData = new FormData();

    formData.append("upfile", file);

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  getShareInfo(share_id) {
    return http.get("/d/" + share_id);
  }

  accept() {
    return http.get("/a/");
  }
}

export default new FilesService();
