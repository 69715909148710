<template>
    <div>
      <v-btn 
        v-if="!user.username" 
        @click="SignIn"
        text 
        > 
        <v-icon left>mdi-login-variant</v-icon>
        Log In
      </v-btn>
      <v-btn v-else @click="SignOut" text>Log Out
        <v-icon right>mdi-logout-variant</v-icon>
      </v-btn>
    </div>
<!--
  <div class="navbar-menu">
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a :href="github" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-github fa-2x" aria-hidden="true"></i>
          </a>
          <a :href="twitter" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-twitter fa-2x" aria-hidden="true"></i>
          </a>
          <a
            v-if="!account"
            @click="SignIn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fas fa-sign-in-alt fa-2x" aria-hidden="false"></i>
            LogIn
          </a>
          <a v-else @click="SignOut" target="_blank" rel="noopener noreferrer">
            <i class="fas fa-sign-out-alt fa-2x" aria-hidden="false"> </i>
            LogOut
          </a>
          <div v-if="account">{{ account.name }} yeah</div>
        </div>
      </div>
    </div>
  </div>
  -->
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';

export default {
  name: 'UserControlBar',
  
  data() {
      return {

      }
  },
  computed: {
      user() {
          return this.$store.getters.user
      }
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    console.log(accounts)
    if (accounts.length == 0) {
      return;
    }
    this.$store.dispatch("setUser", accounts[0])
    //this.$emit.emit('login', this.account);
  },
  methods: {
    async SignIn() {
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          const accounts = this.$msalInstance.getAllAccounts();
          this.$store.dispatch("setUser", accounts[0])
          //this.$emit('login', this.account);
        })
        .catch(error => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      await this.$msalInstance
        .logout({})
        .then(() => {
            this.$store.dispatch("setUser", {})
          //this.$emit('logout', 'logging out');
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>