<template>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">СПОРАЗУМЕНИЕ ЗА КОНФИДЕНЦИАЛНОСТ</span>
        </v-card-title>
        <v-card-text>

<div class='subtitle-2 my-2' >1.	ПРЕДМЕТ НА СПОРАЗУМЕНИЕТО</div>
<p class="text-justify">
Настоящото „СПОРАЗУМЕНИЕ ЗА КОНФИДЕНЦИАЛНОСТ“ („Споразумението“) определя правилата за регулиране на взаимоотношенията между Получаващата страна и Блубери във връзка с опазването на Конфиденциалната информация, която Блубери предоставя на Получаващата страна с настоящото Споразумение и с приложените към него Оферти, Графици и други Приложения (в случай, че има такива), както и всяка Конфиденциална информация, която ще бъде предоставяна в бъдеще във връзка с Целта. С и от момента на приемането на настоящото Споразумение от страна на Клиента, ще се счита, че надлежно е сключено валидно и обвързващо споразумение между Страните. Клиентът се съгласява, че сключването на Споразумението се извършва по електронен метод, а именно чрез натискането на бутона „Приемам“.
</p>

<div class='subtitle-2 my-2' >2.	РАЗКРИВАЩА СТРАНА:</div>
<p class="text-justify">
„БЛУБЕРИ УОТЪР“ ООД, ЕИК 206335491, дружество, надлежно регистрирано и съществуващо съгласно българското законодателство, със седалище и адрес на управление: гр. София, район „Витоша“, ж.к. „Бояна“, ул. „Три кладенци“ № 2, вх. „Б“, ап. 14, представлявано от управителя Симеон Владиславов Кунински, ЕГН 8812274125
</p>

<div class='subtitle-2 my-2' >3.	ПОЛУЧАВАЩА СТРАНА: </div>
<p class="text-justify">
Получаващата страна в зависимост от обстоятелствата може да бъде физическо или юридическо лице или друго правно образувание, на което е разкрита Конфиденциалната информация на Блубери.
</p>

<div class='subtitle-2 my-2' >4.	ОПРЕДЕЛЕНИЯ</div>

<p class="text-justify">
В настоящото споразумение, освен ако от контекста не следва друго:
</p>
<p class="text-justify">
“Блубери” означава „Блубери Уотър“ ООД, ЕИК 206335491, дружество, надлежно регистрирано и съществуващо съгласно българското законодателство, със седалище и адрес на управление: гр. София, район „Витоша“, ж.к. „Бояна“, ул. „Три кладенци“ № 2, вх. „Б“, ап. 14, неговото предприятие-майка „Блубери груп“ ООД, ЕИК 206301350, както и неговите настоящи и бъдещи свързани и/или асоциирани дружества (включително, но не само: „Блубери консултинг“ ООД, ЕИК 206335064, „Блубери софтуер“ ООД, ЕИК 206335210, „Блубери консилио“ ООД, ЕИК 206336935, „Блубери продъктс“ ООД, ЕИК 206385124).
</p>
<p class="text-justify">
“Конфиденциална информация” означава каквато и да било информация, данни или и двете, разкрита от или от името на Разкриващата страна на Получаващата страна, включително, но не само, всякакви видове бизнес, търговска или техническа информация, данни и/или оферти във връзка с Целта, с изключение на информация която е явно неконфиденциална по своето естество. Информацията следва да бъде третирана като Конфиденциална информация, независимо от носителя на който тази информация или данни са възпроизведени, както и дали Конфиденциалната информация е разкрита устно, визуално или по друг начин. Като Конфиденциална информация следва да бъдат третирани и взаимоотношенията, включително, но не само съществуването на настоящото Споразумение, между Разкриващата страна и Получаващата страна.
</p>
<p class="text-justify">
“Разкриваща страна” означава Страната която разкрива Конфиденциална информация на Получаващата страна. Конфиденциална информация може също да бъде разкрита на Получаващата страна от работниците, представителите, служителите, консултантите, други представители, свързани лица, подизпълнители, предприятието-майка или свързани или асоциирани дружества (по-долу наричани „Представители“) на Разкриващата страна, които се считат за Разкриваща страна за целите на настоящото Споразумение. 
</p>
<p class="text-justify">
“Позволени получатели” означава всеки директор, работник, представител, служител, консултант, одитор, друг представител, свързано лице или подизпълнител на Получаващата страна, който с основание има нужда да знае Конфиденциалната информация с оглед на Целта.
</p>
<p class="text-justify">
“Цел” означава настоящите или бъдещите взаимоотношения между Блубери и Получаващата страна, във връзка с които се предоставя Конфиденциалната информация.
</p>
<p class="text-justify">
“Получаваща страна” (обозначавана и с „Вие“, „Ваш“ и други производни) означава Страната, получаваща Конфиденциална информация от Разкриващата страна.
</p>
<p class="text-justify">
„Страни“ означава Разкриващата страна и Получаващата страна.
</p>

<div class='subtitle-2 my-2' >5.	ЗАДЪЛЖЕНИЯ ЗА КОНФИДЕНЦИАЛНОСТ И ОГРАНИЧЕНО ПОЛЗВАНЕ</div>
<p class="text-justify">
Получаващата страна се задължава:
</p>
<p class="text-justify">
5.1.	да не разкрива Конфиденциалната информация на никого, с изключение на Позволените получатели, които са обвързани със същите задължения за конфиденциалност, като установените с настоящото Споразумение;
</p>
<p class="text-justify">
5.2.	да използва Конфиденциалната информация изключително за Целта;
</p>
<p class="text-justify">
5.3.	да пази поверителността и съхранява Конфиденциалната информация с не по-малка грижа от тази, която Получаващата страна полага за собствената си конфиденциална информация;
</p>
<p class="text-justify">
5.4.	да осигури Позволените получатели да се задължат по отношение на Конфиденциалната информация, която им е разкрита, да спазват настоящото Споразумение така, както ако те самите биха били Получаваща страна и, ако Разкриващата страна поиска, да осигури всеки Позволен получател да сключи споразумение за конфиденциалност с Разкриващата страна при условия, еквивалентни на тези съдържащи се в настоящото споразумение; и
</p>
<p class="text-justify">
5.5.	във всеки момент да носи отговорност за неизпълнението на настоящото Споразумение от страна на който и да било Позволен получател.
</p>

<div class='subtitle-2 my-2' >6.	ИЗКЛЮЧЕНИЯ ОТ ЗАДЪЛЖЕНИЯТА ЗА КОНФИДЕНЦИАЛНОСТ И ОГРАНИЧЕНО ПОЛЗВАНЕ</div>
<p class="text-justify">
6.1.	Задълженията по точка 5 за опазване на конфиденциалността на цялата Конфиденциална информация не следва да се прилагат, ако Получаващата страна може да докаже, че тази информация:
</p>
<p class="text-justify">
6.1.1.	е била на разположение на Получаващата страна, без задължение за конфиденциалност, преди получаването ѝ от Разкриващата страна;
</p>
<p class="text-justify">
6.1.2.	в момента на разкриването е, или след това стане, обществено достъпна, не в резултат на нарушение на настоящото Споразумение от Получаващата страна или от Позволен получател;
</p>
<p class="text-justify">
6.1.3.	е законно придобита от Получаващата страна от трето лице, без задължение за конфиденциалност, при условие, че доколкото е известно на Получаващата страна, това трето лице не е нарушило каквито и да било задължения за конфиденциалност към Разкриващата страна по отношение на тази информация; или 
</p>
<p class="text-justify">
6.1.4.	е разработена от Получаващата страна независимо от каквато и да било Конфиденциална информация.
</p>
<p class="text-justify">
6.2.	Получаващата страна ще се счита в нарушение на настоящото Споразумение, освен ако не може да докаже, че което и да било разкриване на Конфиденциална информация е било извършено само и до степента необходима за спазването на законово, съдебно наложено или друго задължение със задължителен характер, по-долу наричано „Законово задължение“. В случай че по някоя от тези причини е извършено разкриване, Страната извършваща разкриването трябва да осигури, че получателят на Конфиденциална информация е уведомен и от него е изискано да зачита конфиденциалността. Подобно разкриване по никакъв начин не намалява задълженията на Страните по настоящото Споразумение, освен ако Получаващата страна е принудена по силата на Законово задължение да разкрие Конфиденциална информация без ограничение.
</p>
<p class="text-justify">
6.3.	До степента, която е позволена от Законовото задължение, Получаващата страна трябва незабавно и писмено да уведоми другата Страна, когато ѝ стане известно разследване или процес от каквото и да било естество, по силата на които е възможно да бъде изискано разкриването на Конфиденциалната информация на другата Страна с оглед спазването на Законово задължение.
</p>

<div class='subtitle-2 my-2' >7.	КОПИЯ</div>
Освен ако не е упоменато друго от Разкриващата страна в момента на разкриването, Получаващата страна може да прави копия на Конфиденциалната информация, доколкото това е необходимо за Целта.

<div class='subtitle-2 my-2' >8.	ОТКАЗ</div>
<p class="text-justify">
8.1.	Никоя клауза от настоящото Споразумение не задължава някоя от Страните да разкрие каквато и да било информация.
</p>
<p class="text-justify">
8.2.	Всяка от Страните има право да откаже да приеме информация съгласно настоящото Споразумение, преди нейното разкриване. Конфиденциална информация, разкрита въпреки изричен предварителен отказ, не се обхваща от задълженията по настоящото споразумение.
</p>

<div class='subtitle-2 my-2' >9.	ЛИПСА НА ЛИЦЕНЗ ИЛИ ПРАВО НА СОБСТВЕНОСТ</div>
<p class="text-justify">
Никоя клауза от настоящото Споразумение не засяга правата, които Разкриващата страна има по отношение на Конфиденциалната информация, нито пък настоящото Споразумение предоставя на Получаващата страна каквото и да било право или лиценз относно патенти, авторски права, търговски тайни, ноу-хау или други подобни във връзка с Конфиденциалната информация, освен правото на ползване на Конфиденциалната информация във връзка с Целта и в съответствие с настоящото споразумение.
</p>

<div class='subtitle-2 my-2' >10.	НЕЗАОБИКАЛЯНЕ</div>
<p class="text-justify">
10.1.	Страните изрично уговарят, че във всеки момент преди изтичането на 24 (двадесет и четири) месеца от датата на настоящото Споразумение идентичността на физически или юридически лица или други трети лица, включително, но не само, доставчици, клиенти, източници на финансиране, производители и консултанти, обсъждани и поставени на разположение от Разкриващата страна във връзка с Целта, както и всяка свързана бизнес възможност, съставляват Конфиденциална информация и Получаващата страна или нейни свързани/асоциирани юридически или физически лица нямат право (без предварителното писмено съгласие на Разкриващата страна):
</p>
<p class="text-justify">
10.1.1.	пряко или косвено да започват, искат, преговарят, сключват договори или влизат в каквито и да било бизнес трансакции, сделки или предприятия с трети страни, разкрити или представени от Разкриващата страна; или
</p>
<p class="text-justify">
10.1.2.	да опитват да заобиколят, конкурират или избягват Разкриващата страна по отношение на каквито и да било бизнес възможности, свързани с Целта, служейки си с Конфиденциална информация или по друг начин използвайки или извличайки ползи от Конфиденциалната информация.
</p>
<p class="text-justify">
10.2.	Получаващата страна се съгласява, че всеки финансов приход, реализиран от нея, или от нейно свързано или асоциирано лице, в резултат на нарушение на точка 10.1, следва да бъде прехвърлено по сметка на Разкриващата страна, като до момента на прехвърлянето, върху такава дължима сума ще се начислява лихва в размер на 4% годишно. Лихвата ще се начислява ежедневно от датата от която е дължима до плащането на сумата, независимо дали преди или след съдебно решение, и Получаващата страна ще плати лихвата заедно с главницата на дължимата сума.
</p>
<p class="text-justify">
10.3.	Точка 10.2 не засяга възможността на Разкриващата страна да претендира вреди над размера на лихвата по точка 10.2, в случай на нарушение на уговорките по точка 10.1.
</p>

<div class='subtitle-2 my-2' >11.	ЛИЧНИ ДАННИ</div>
<p class="text-justify">
11.1.	С приемането на настоящото Споразумение Вие се съгласявате, че Блубери може да събира, обработва и съхранява лични данни по смисъла на Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета от 27 април 2016 година относно защитата на физическите лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на Директива 95/46/EО (наричан за краткост „Регламент (ЕС) 2016/679“) ако са предоставени директно от Вас – Вашия имейл адрес, име, домашен или служебен адрес и телефонен номер, както и данните на Вашите ръководни лица, служители и представители, които подавате с изрично съгласие и във връзка с Целта, и които данни могат да се събират, обработват и съхраняват на основание чл. 6, пар. 1, б. „а“ от Регламент (ЕС) 2016/679.
</p>
<p class="text-justify">
11.2.	Други лични данни по смисъла на Регламент (ЕС) 2016/679, които могат да се събират, обработват и съхраняват от Блубери, без изричното им предоставяне от субектите на лични данни, с използването на настоящата форма за споделяне на информация, посредством използване на аналитични инструменти, относно детайли за посещението и използването на уеб сайта, както и относно достъпваните ресурси, което включва трафик данни за дата и час на посещението, локация, IP адрес, браузър, операционна система, размер на екран, както и други логове от електронната комуникация, е за целите по изпълнението на договорите между субектите на лични данни и преддоговорните отношения между страните, на основание чл. 6, пар. 1, б. „а“ от Регламент (ЕС) 2016/679.
</p>
<p class="text-justify">
11.3.	С използването на настоящата форма за споделяне на информация, Вие се съгласявате, че Блубери може да събира, обработва и съхранява подобни данни (независимо дали са събрани по електронен начин), и може да ги споделя при спазването на законовите задължения за това, с трети страни – държавни органи, лица с публични функции и правомощия, или процесуални представители на Блубери, както и представители и/или служители на Блубери, за защита на своите права и законни интереси, както и за изпълнение на преддоговорни или договорни задължения.
</p>
<p class="text-justify">
11.4.	На основание чл. 17, пар. 3, б. „д“ от Регламент (ЕС) 2016/679, Блубери няма задължението при поискване да изтрие събраните, обработени и съхранени лични данни, при използването на настоящата форма за споделяне на информация, доколкото същите са необходими за установяването, упражняването или защитата на правни претенции на Блубери, свързани с нерегламентираното разкриване на Конфиденциална информация.
</p>

<div class='subtitle-2 my-2' >12.	ЛИПСА НА ГАРАНЦИЯ</div>
<p class="text-justify">
Разкриващата страна ще разкрива Конфиденциалната информация във вида, в който е, и не гарантира, че която и да било част от информацията, която се разкрива, е пълна, точна, без недостатъци или права на трети лица, или полезна за Целта или за други цели на Получаващата страна.
</p>

<div class='subtitle-2 my-2' >13.	ЛИПСА НА ДОПЪЛНИТЕЛНИ ЗАДЪЛЖЕНИЯ</div>
<p class="text-justify">
Настоящото Споразумение не:
</p>
<p class="text-justify">
13.1.	създава каквото и да било друго правоотношение;
</p>
<p class="text-justify">
13.2.	задължава Страна по него да сключи какъвто и да било друг договор; или
</p>
<p class="text-justify">
13.3.	изисква насрещна престация за каквато и да било част от получена информация.
</p>

<div class='subtitle-2 my-2' >14.	СРОК</div>
<p class="text-justify">
Настоящото Споразумение влиза в сила от момента на приемането му от Получаващата страна по начина, описан в точка 1. Клаузите, регулиращи опазването на конфиденциалността на Конфиденциалната информация, обвързват Страните безсрочно. 
</p>

<div class='subtitle-2 my-2' >15.	ЗАДЪЛЖЕНИЯ СЛЕД ИЗПЪЛНЕНИЕ НА ЦЕЛТА</div>
<p class="text-justify">
В случай на изпълнение на Целта на настоящото Споразумение (чрез прекратяване на взаимоотношенията между Страните или по друг начин), Получаващата страна е длъжна да спре да използва Конфиденциалната информация.
</p>

<div class='subtitle-2 my-2' >16.	НЕИЗПЪЛНЕНИЕ И ЗАЩИТА ОТ НЕИЗПЪЛНЕНИЕ</div>
<p class="text-justify">
16.1.	Получаващата страна трябва да обезщети и във всеки момент да обезщетява напълно Разкриващата страна срещу всички задължения, разноски (включително съдебни разноски), разходи, вреди и загуби (включително всякакви преки, косвени или свързани загуби, загуба на печалба, загуба на репутация и всички лихви, неустойки и други основателни разноски и разходи), претърпени от Разкриващата страна, произтичащи от нарушение на настоящото Споразумение, извършено от Получаващата страна или от действия или бездействия на Позволен получател. 
</p>
<p class="text-justify">
16.2.	Всички задължения, разноски (включително съдебни разноски), разходи, вреди и загуби (включително всякакви преки, косвени или свързани загуби, загуба на печалба, загуба на репутация и всички лихви, неустойки и други основателни разноски и разходи), претърпени от предприятието-майка на Разкриващата страна или от нейните настоящи и бъдещи свързани и/или асоциирани дружества, ще бъдат считани за претърпени от Разкриващата страна в отношенията между Страните и Получаващата страна ще дължи обезщетяването им по начина, описан в точка 16.1.
</p>
<p class="text-justify">
16.3.	В допълнение към средствата за защита по приложимото законодателство, Страните приемат, че нарушението на разпоредби от настоящото Споразумение може да доведе до непоправими вреди за Разкриващата страна, които е възможно да не могат да бъдат компенсирани с парично обезщетение. Следователно, при всяко нарушение на която и да било разпоредба на настоящото Споразумение, Разкриващата страна може да се сдобие от всеки компетентен съд с предварителна, временна или постоянна заповед, възпираща или забраняваща нарушението на Получаващата страна или юридическо или физическо лице, действащи съгласувано с Получаващата страна.
</p>

<div class='subtitle-2 my-2' >17.	УНИЩОЖАВАНЕ</div>
<p class="text-justify">
17.1.	След изпълнение на Целта на настоящото Споразумение (чрез прекратяване на взаимоотношенията между Страните или по друг начин), Разкриващата страна може да поиска унищожаването на Конфиденциалната информация. Унищожаване означава изпълнение на подходящи мерки за връщане или унищожаване на всички копия, включително електронните. Унищожаването трябва да бъде потвърдено писмено. Унищожаването трябва да бъде извършено до 30 (тридесет) дни от искането.
</p>
<p class="text-justify">
17.2.	Разпоредбите за унищожаване не се прилагат към електронни копия на Конфиденциална информация, създадени в процеса на рутинно създаване на резервни копия, и към Конфиденциална информация или нейни копия които следва да бъдат съхранявани от Получаващата страна или нейните консултанти в съответствие с разпоредби на действащото законодателство, при условие, че тази Конфиденциална информация или нейни копия ще бъдат предмет на продължаващите задължения за конфиденциалност съгласно настоящото Споразумение; при всички положения, от датата на искането не е разрешено използване на Конфиденциалната информация.
</p>

<div class='subtitle-2 my-2' >18.	ДОБРОСЪВЕСТНОСТ И ПОЧТЕНОСТ В ОБОРОТА</div>
<p class="text-justify">
При изпълнение на задълженията им по настоящото Споразумение, Страните ще действат в съответствие с принципите на добросъвестност и почтеност в оборота. Разпоредбите на настоящото Споразумение, както и каквито и да било изявления, направени от Страните във връзка с настоящото Споразумение, следва да бъдат тълкувани в съответствие с принципите на добросъвестност и почтеност в оборота.
</p>

<div class='subtitle-2 my-2' >19.	РАЗДЕЛНОСТ, ПРИЛОЖИМО ПРАВО И ЮРИСДИКЦИЯ</div>
<p class="text-justify">
19.1.	Пълната или частична недействителност на разпоредба на настоящото Споразумение не засяга законността, действителността и възможността за принудително изпълнение на която и да било друга разпоредба от настоящото Споразумение.
</p>
<p class="text-justify">
19.2.	Настоящото споразумение и който и да било спор или претенция произтичащи от или във връзка с него или с неговия предмет или сключване (включително извъндоговорни спорове или претенции) ще бъдат уреждани и тълкувани съгласно българското законодателство.
</p>
<p class="text-justify">
19.3.	Страните неотменимо се съгласяват, че съдилищата в България със седалище в София ще имат изключителна юрисдикция за уреждане на спорове или претенции произтичащи от или във връзка с него или с неговия предмет или сключване (включително извъндоговорни спорове или претенции).
</p>

<div class='subtitle-2 my-2' >20.	БЕЗ ВЪЗЛАГАНЕ</div>
<p class="text-justify">
Настоящото споразумение не може да бъде възлагано от която и да е от Страните без предварителното писмено съгласие на другата Страна, което не може да бъде оттегляно без причина. Евентуално възлагане не освобождава съответната Страна от задълженията ѝ по настоящия Договор по отношение на Конфиденциалната информация разкрита на тази Страна преди договореното възлагане.
</p>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            v-on:click="accept()"
          >
            Приемам
          </v-btn>
        </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "TermsOfUse",
  props: ["show"],
  computed: {
    dialog() {
      return this.show;
    },
  },
  data() {
    return {};
  },
  methods: {
    accept() {
      console.log("accept");
      this.$emit("change");
    },
  },
};
</script>