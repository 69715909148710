<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar dense>
        

        <v-toolbar-title>Blueberry Share</v-toolbar-title>

        <v-spacer></v-spacer>
        <template v-if="user.username">
          <v-btn to='' text>My Files<v-icon right>mdi-view-list</v-icon></v-btn>
          <v-btn to="/upload" text>Upload<v-icon right>mdi-file-upload</v-icon></v-btn>
        </template>
        <UserControlBar />
      </v-toolbar>
    </v-app-bar>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <v-row justify="center">
          <router-view></router-view>

          
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import UserControlBar from "./components/user-control-bar";
//import TermsOfUse from "./components/terms-of-use";

export default {
  name: "App",

  components: {
    UserControlBar,
    //  TermsOfUse,
  },

  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>