<template>
  <div v-if="this.share.name">
    <TermsOfUse :show="!this.share.tou" v-on:change="accept" />

    <v-card v-if="this.share.tou" class="my-15" min-width="500">
      <v-card-title>Secure File Transfer</v-card-title>

      <v-card-text>
        <v-row>
          <v-col md="3">Creation Date</v-col>
          <v-col md="9" class="font-weight-medium">{{ date }}</v-col>
        </v-row>
        <v-row class="ma0">
          <v-col md="3">Size</v-col>
          <v-col md="9" class="font-weight-medium">{{ size }}</v-col>
        </v-row>
        <v-row>
          <v-col md="3">Name</v-col>
          <v-col md="9" class="font-weight-medium">{{ share.name }}</v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-actions class="justify-center">
        <v-btn
          color="deep-purple purple white--text"
          :href="link"
          target="_blank"
        >
          Download File
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import FilesService from "@/services/files";
import TermsOfUse from "@/components/terms-of-use";

export default {
  name: "Download",
  props: ["share_id"],
  components: { TermsOfUse },

  data() {
    return {
      share: {},
      terms: 0,
    };
  },
  created() {
      
    this.updateData();
  },
  computed: {
    size() {
      return Math.round(this.share.size / 1024) + " kB";
    },
    date() {
      var a = new Date(this.share.creation_date * 1000);
      return (
        a.getDate() +
        "." +
        (a.getMonth() + 1) +
        "." +
        a.getFullYear() +
        " " +
        a.getHours() +
        ":" +
        a.getMinutes()
      );
    },
    link() {
      return "https://share.blueberry.bg/api/dl/" + this.share.uuid;
    },
  },
  methods: {
    updateData() {
      FilesService.getShareInfo(this.$route.params.share_id).then((resp) => {
        this.share = resp.data;
      });
    },
    accept() {
      console.log("ACCEPTED");
      FilesService.accept().then(() => {
        this.updateData();
      });
    },
  },
};
</script>